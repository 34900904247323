import { useAppStore } from "./app";
import { useMenuStore } from "./menu";
import { useProcessStore } from "./process";
import { useUserStore } from "./user";
import { useShopStore } from "./shop";
import { useTakephotoStore } from "./takephoto";
import { useWatermaskStore } from "./watermask";
import { useLangStore } from "./lang";

export function useBaseStore() {
	const app = useAppStore();
	const menu = useMenuStore();
	const process = useProcessStore();
	const user = useUserStore();
	const takephoto = useTakephotoStore();
	const shop = useShopStore();
	const watermask = useWatermaskStore();
	const lang = useLangStore();

	return {
		app,
		menu,
		process,
		takephoto,
		shop,
		user,
		watermask,
		lang
	};
}

const useStore = useBaseStore;

export { useStore };

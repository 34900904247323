import { BaseService, Service } from "/@/cool";
@Service("teamMember")
class TeamMember extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["update"] = prefix + "update";
		this.permission["page"] = prefix + "page";
		this.permission["list"] = prefix + "list";
		this.permission["info"] = prefix + "info";
		this.permission["pageMemberReward"] = prefix + "pageMemberReward";
		this.permission["pageMemberRewardDetail"] = prefix + "pageMemberRewardDetail";
		this.permission["updateSetting"] = prefix + "updateSetting";
		this.permission["batchUpdateSetting"] = prefix + "batchUpdateSetting";
		// 修模师任务报告
		this.permission["reportMemberTask"] = prefix + "reportMemberTask";
		// 修模能力分析
		this.permission["reportMemberAbility"] = prefix + "reportMemberAbility";

		//查看个人能力的报表
		this.permission["myAbilityAnalysis"] = prefix + "myAbilityAnalysis";
		//查看个人任务的报表
		this.permission["myRepairOrder"] = prefix + "myRepairOrder";

		this.permission["listByTeamId"] = prefix + "listByTeamId";
		// "/admin/team/page",
		// "/admin/teamMember/pageMemberRewardDetail",
		// "/admin/teamMember/pageMemberReward"


	}

	pageMemberReward(data: any) {
		return this.request({
			url: "/pageMemberReward",
			method: "POST",
			data
		});
	}

	pageMemberRewardDetail(data: any) {
		return this.request({
			url: "/pageMemberRewardDetail",
			method: "POST",
			data
		});
	}

	updateSetting(data: any) {
		return this.request({
			url: "/updateSetting",
			method: "POST",
			data
		});
	}

	batchUpdateSetting(data: any) {
		return this.request({
			url: "/batchUpdateSetting",
			method: "POST",
			data
		});
	}
	//修模师任务报告
	reportMemberTask(data: any) {
		return this.request({
			url: "/reportMemberTask",
			method: "POST",
			data
		});
	}
	//修模能力分析
	abilityAnalysis(data: any) {
		return this.request({
			url: "/reportMemberAbility",
			method: "POST",
			data
		});
	}

	//获取团队的成员列表
	listByMemberId(data: any) {
		return this.request({
			url: "/listByTeamId",
			method: "POST",
			data
		});
	}

	//获取成员任务情况列表
	listMemberTask(data: any) {
		return this.request({
			url: "/memberTaskList",
			method: "POST",
			data
		});
	}

	//根据成员的user_id 获取成员所待过的团队
	getTeamIdsByUserId(data: any) {
		return this.request({
			url: "/getTeamIdsByUserId",
			method: "POST",
			data
		});
	}
}
export default TeamMember;

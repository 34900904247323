import { BaseService, Service } from "/@/cool";
@Service("messageList")
class MessageList extends BaseService {
	//根据消息id 获取消息
	getMessageById(data: { id?: number }) {
		return this.request({
			url: "/info",
			method: "POST",
			data
		});
	}

	//获取消息列表
	getMessageList(data: { nums?: number }) {
		return this.request({
			url: "/list",
			method: "POST",
			data
		});
	}

	//更新消息的记录状态未已读
	updateMessageStatus(data: {}) {
		return this.request({
			url: "/updateMessageRecordStatus",
			method: "POST",
			data
		});
	}

	//获取用户列表
	UsersList(data: {}) {
		return this.request({
			url: "/usersList",
			method: "GET",
			data
		});
	}
}
export default MessageList;

import { BaseService, Service, Permission } from "/@/cool";
@Service("takephoto")
class Takephoto extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["shopList"] = prefix + "shopList";
		this.permission["equipmentList"] = prefix + "equipmentList";
		this.permission["customerList"] = prefix + "customerList";
		this.permission["takephoto"] = prefix + "takephoto";
		this.permission["syncCameraStatus"] = prefix + "syncCameraStatus";
		this.permission["syncCameraStatus"] = prefix + "syncCameraStatus";
		this.permission["cameraStatus"] = prefix + "cameraStatus";
		this.permission["reloadCamera"] = prefix + "reloadCamera";
		this.permission["setTakephotoStatus"] = prefix + "setTakephotoStatus";
		this.permission["sendcmd"] = prefix + "sendcmd";
		this.permission["innerSendcmd"] = prefix + "innerSendcmd";
		this.permission["videoKeyToken"] = prefix + "videoKeyToken";
	}

	shopList(params: any) {
		return this.request({
			url: "/shopList",
			method: "POST",
			data: {
				...params
			}
		});
	}

	equipmentList(params: any) {
		return this.request({
			url: "/equipmentList",
			method: "POST",
			data: {
				...params
			}
		});
	}

	customerList(params: any) {
		return this.request({
			url: "/customerList",
			method: "POST",
			data: {
				...params
			}
		});
	}
	//获取国外排队的客户列表
	customerForeignList(params: any) {
		return this.request({
			url: "/customerForeignList",
			method: "POST",
			data: {
				...params
			}
		});
	}

	takephoto(params: any) {
		return this.request({
			url: "/takephoto",
			method: "POST",
			data: {
				...params
			}
		});
	}

	syncCameraStatus(params: any) {
		return this.request({
			url: "/syncCameraStatus",
			method: "POST",
			data: {
				...params
			}
		});
	}

	cameraStatus(params: any) {
		if (params.host) {
			return this.request({
				url: `${params.host}/client/cameraStatus`,
				method: "GET",
				data: {
					...params
				}
			});
		}
		return this.request({
			url: "/cameraStatus",
			method: "POST",
			data: {
				...params
			}
		});
	}

	reloadCamera(params: any) {
		return this.request({
			url: "/reloadCamera",
			method: "POST",
			data: {
				...params
			}
		});
	}

	setTakephotoStatus(params: any) {
		return this.request({
			url: "/setTakephotoStatus",
			method: "POST",
			data: {
				...params
			}
		});
	}

	sendcmd(params: any) {
		return this.request({
			url: "/sendcmd",
			method: "POST",
			data: {
				...params
			}
		});
	}

	// 内部馆专用指令
	innerSendcmd(params: any) {
		return this.request({
			url: "/innerSendcmd",
			method: "POST",
			data: {
				...params
			}
		});
	}

	videoKeyToken(params: any) {
		return this.request({
			url: "/videoKeyToken",
			method: "POST",
			data: { ...params }
		});
	}
}
export default Takephoto;

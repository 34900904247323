import { BaseService, Service } from "/@/cool";
@Service("allRepairOrder")
class AllRepairOrder extends BaseService {

    // constructor() {
    //     super();

    //     let prefix = "";
    //     if (this.namespace) {
    //         prefix = this.namespace.replace(/\//g, ":") + ":";
    //     }
    //     this.permission["add"] = prefix + "add";
    // }

    // add(params: any) {
    //     return this.request({
    //         url: "/add",
    //         method: "POST",
    //         data: { ...params }
    //     });
    // }

    allRepairOrderPage(params: any) {
        return this.request({
            url: "/allRepairOrderPage",
            method: "GET",
            params: { ...params }
        });
    }

    priorityOrderList(params: any) {
        return this.request({
            url: "/priorityOrderList",
            method: "POST",
            params: { ...params }
        });
    }

}
export default AllRepairOrder;

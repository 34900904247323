import { BaseService, Service } from "/@/cool";
@Service("workOrderQuestionTag")
class WorkOrderQuestionTag extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["all"] = prefix + "all";
	}

	getQuestionReason(data: any) {
		return this.request({
			url: "/all",
			method: "POST",
			data
		});
	}
}

export default WorkOrderQuestionTag;

import { BaseService, Service } from "/@/cool";
@Service("modelBugFeedback")
class ModelBugFeedback extends BaseService {

    constructor() {
        super();

        let prefix = "";
        if (this.namespace) {
            prefix = this.namespace.replace(/\//g, ":") + ":";
        }
        this.permission["add"] = prefix + "add";
    }

    add(params: any) {
        return this.request({
            url: "/add",
            method: "POST",
            data: { ...params }
        });
    }

}
export default ModelBugFeedback;

import { BaseService, Service } from "/@/cool";
@Service("modelRepairCheckItem")
class ModelRepairCheckItem extends BaseService {
	first(params: any) {
		return this.request({
			url: "/first",
			method: "POST",
			data: {
				...params
			}
		});
	}
}
export default ModelRepairCheckItem;

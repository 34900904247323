import { defineStore } from "pinia";
import { ref } from "vue";
import { deepMerge, storage } from "/@/cool/utils";

const ZHCN = "zh-cn";
const EN = "en";

export const useLangStore = defineStore("lang", function () {
	// 基本信息
	const info = ref<String>(ZHCN);
	const list = ref<String[]>([ZHCN, EN]);
	// 设置基本信息
	function set(data: any) {
		deepMerge(info, data);
		storage.set("lang", info);
	}

	function isZh() {
		return info.value === ZHCN;
	}

	function switchLang() {
		if (info.value == ZHCN) {
			set(EN);
		} else {
			set(ZHCN);
		}
	}

	return {
		info,
		set,
		list,
		isZh,
		switchLang
	};
});

import { BaseService, Service } from "/@/cool";
@Service("order")
class Order extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["makeOrderCheck"] = prefix + "makeOrderCheck";
		this.permission["makeOrderV2"] = prefix + "makeOrderV2";
		this.permission["makeQueueOrderV2"] = prefix + "makeQueueOrderV2";
		this.permission["goods"] = prefix + "goods";
		this.permission["goodsV2"] = prefix + "goodsV2";
		this.permission["makeAddPrintOrderV2"] = prefix + "makeAddPrintOrderV2";
	}

	makeOrderCheck(params: any) {
		return this.request({
			url: "/makeOrderCheck",
			method: "POST",
			data: {
				...params
			}
		});
	}

	makeOrderV2(params: any) {
		return this.request({
			url: "/makeOrderV2",
			method: "POST",
			data: {
				...params
			}
		});
	}

	makeQueueOrderV2(params: any) {
		return this.request({
			url: "/makeQueueOrderV2",
			method: "POST",
			data: {
				...params
			}
		});
	}

	// 商品
	goods(params: any) {
		return this.request({
			url: "/goods",
			method: "POST",
			data: {
				...params
			}
		});
	}
	// 商品
	goodsV2(params: any) {
		return this.request({
			url: "/goodsV2",
			method: "POST",
			data: {
				...params
			}
		});
	}

	makeAddPrintOrderV2(params: any) {
		return this.request({
			url: "/makeAddPrintOrderV2",
			method: "POST",
			data: {
				...params
			}
		});
	}
}
export default Order;

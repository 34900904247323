import { BaseService, Service } from "/@/cool";
@Service("missionCenter")
class MissionCenter extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		// 任务领取
		this.permission["receive"] = prefix + "receive";
		this.permission["page"] = prefix + "page";
		this.permission["list"] = prefix + "list";
		this.permission["info"] = prefix + "info";
	}

	// 任务领取
	receive(params: any) {
		return this.request({
			url: "/receive",
			method: "POST",
			data: { ...params }
		});
	}

	//修模任务列表
	repairOrderListWithout(data: any) {
		return this.request({
			url: "/repairOrderListWithout",
			method: "POST",
			data
		});
	}
}
export default MissionCenter;

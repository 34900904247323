import { BaseService, Service } from "/@/cool";
@Service("wechatQueueTakephoto")
class WechatQueueTakephoto extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["page"] = prefix + "page";
		this.permission["goods"] = prefix + "goods";
		this.permission["makeOrder"] = prefix + "makeOrder";
	}

	// 商品
	goods(params: any) {
		return this.request({
			url: "/goods",
			method: "POST",
			data: {
				...params
			}
		});
	}
}
export default WechatQueueTakephoto;

import { BaseService, Service } from "/@/cool";
@Service("weChatAuthenticationLogin")
class WeChatAuthenticationLogin extends BaseService {
	certifiedTel(data: any) {
		return this.request({
			url: "/certifiedTel",
			method: "POST",
			data
		});
	}

	userWxInfo(data: any) {
		return this.request({
			url: "/userWxInfo",
			method: "POST",
			data
		});
	}

	//解绑微信
	unbindingWx(data: any) {
		return this.request({
			url: "/unbindingWx",
			method: "POST",
			data
		});
	}

	//绑定老板微信
	wxAddBossInfo(data: any) {
		return this.request({
			url: "/wxAddBossInfo",
			method: "POST",
			data
		});
	}
}
export default WeChatAuthenticationLogin;

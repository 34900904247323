import { BaseService, Service } from "/@/cool";
@Service("myRepairOrder")
class MyRepairOrder extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["sameCustomerIdRepairOrder"] = prefix + "sameCustomerIdRepairOrder";
		this.permission["downloadAutoModel"] = prefix + "downloadAutoModel";
		this.permission["takephotoImageCount"] = prefix + "takephotoImageCount";
		this.permission["takephotoPreviewImages"] = prefix + "takephotoPreviewImages";
		this.permission["takephotoImages"] = prefix + "takephotoImages";
		this.permission["assignMember"] = prefix + "assignMember";
		this.permission["notice"] = prefix + "notice";
		this.permission["teamCheck"] = prefix + "teamCheck";
		this.permission["downloadPrintModel"] = prefix + "downloadPrintModel";
		this.permission["customerService"] = prefix + "customerService";
		this.permission["page"] = prefix + "page";
		this.permission["list"] = prefix + "list";
		this.permission["info"] = prefix + "info";
		this.permission["receive"] = prefix + "receive";
		this.permission["photo"] = prefix + "photo";
		this.permission["glb"] = prefix + "glb";
		this.permission["reassignMember"] = prefix + "reassignMember";

		//更新上传模型的时间
		this.permission["updateUploadCheckLogTime"] = prefix + "updateUploadCheckLogTime";
		//模型预览权限
		this.permission["modelPreview"] = prefix + "modelPreview";
		//修模状态改为修模中
		this.permission["updateOrderStatusToRepairing"] = prefix + "updateOrderStatusToRepairing";
		//判断上传文件是否存在
		this.permission["isExistUploadFile"] = prefix + "isExistUploadFile";
	}

	//判断上传文件是否存在
	isExistUploadFile(data: any) {
		return this.request({
			url: "/isExistUploadFile",
			method: "POST",
			data
		})
	}

	//转派修模订单
	reassignMember(params: any) {
		return this.request({
			url: "/reassignMember",
			method: "POST",
			data: { ...params }
		});
	}
	sameCustomerIdRepairOrder(params: any) {
		return this.request({
			url: "/sameCustomerIdRepairOrder",
			method: "POST",
			data: { ...params }
		});
	}

	downloadAutoModel(params: any) {
		return this.request({
			url: "/downloadAutoModel",
			method: "POST",
			data: { ...params }
		});
	}

	takephotoImageCount(params: any) {
		return this.request({
			url: "/takephotoImageCount",
			method: "POST",
			data: { ...params }
		});
	}

	takephotoImages(params: any) {
		return this.request({
			url: "/takephotoImages",
			method: "POST",
			data: { ...params }
		});
	}

	// 分配成员
	assignMember(params: any) {
		return this.request({
			url: "/assignMember",
			method: "POST",
			data: { ...params }
		});
	}

	notice(params: any) {
		return this.request({
			url: "/notice",
			method: "POST",
			data: { ...params }
		});
	}

	teamCheck(params: any) {
		return this.request({
			url: "/teamCheck",
			method: "POST",
			data: { ...params }
		});
	}

	downloadPrintModel(params: any) {
		return this.request({
			url: "/downloadPrintModel",
			method: "POST",
			data: { ...params }
		});
	}
	customerService(params: any) {
		return this.request({
			url: "/customerService",
			method: "POST",
			data: { ...params }
		});
	}

	//自行领取任务
	receive(params: any) {
		return this.request({
			url: "/receive",
			method: "POST",
			data: { ...params }
		});
	}

	//上传模型的时候记录日志
	updateUploadCheckLogTime(params: any) {
		return this.request({
			url: "/updateUploadCheckLogTime",
			method: "POST",
			data: { ...params }
		});
	}
	//更新修模订单为修模中状态
	updateOrderStatusToRepairing(params: any) {
		return this.request({
			url: "/updateOrderStatusToRepairing",
			method: "POST",
			data: { ...params }
		});
	}
	takephotoPreviewImages(params: any) {
		return this.request({
			url: "/takephotoPreviewImages",
			method: "POST",
			data: { ...params }
		});
	}

	myHistoryRepairOrder(params: any) {
		return this.request({
			url: "/myHistoryRepairOrder",
			method: "POST",
			data: { ...params }
		});
	}
}
export default MyRepairOrder;

import { defineStore } from "pinia";
import { ref } from "vue";
import { storage } from "/@/cool/utils";
import * as Dict from "/$/takephoto/dict/takephoto";

interface Takephoto {
	[key: string]: any;
}

// 本地缓存
const data = storage.info();

export const useTakephotoStore = defineStore("takephoto", function () {
	// 用户信息
	const info = ref<Takephoto>(data.takephotoInfo || {});

	const timeout = 60000 * 2;
	let timer: any = null;
	// 设置用户信息
	function set(value: any) {
		info.value = value;
		storage.set("takephotoInfo", value);
	}

	function get(equipmentId: Number): string {
		return info.value["takephotoStatus_" + equipmentId];
	}

	function getTimeout(equipmentId: Number): Boolean {
		return !!info.value["timeout_" + equipmentId];
	}

	function setIsTimeout(equipmentId: Number, value: any) {
		const key = "timeout_" + equipmentId;
		info.value[key] = value;
		storage.set("takephotoInfo", info.value);
		console.log("setTimeout", info.value[key], info.value);
	}

	// 清除用户
	function clear() {
		storage.remove("takephotoInfo");
		info.value = {};
	}

	function setStatus(key: any, value: any) {
		info.value[key] = value;
		storage.set("takephotoInfo", info.value);
	}

	function resetTimeout(equipmentId: Number) {
		setIsTimeout(equipmentId, false);
	}

	function isUploading(equipmentId: Number): Boolean {
		const value = get(equipmentId);
		return value == Dict.UPLOADING || value == Dict.START;
	}

	function isTimeout(equipmentId: Number): Boolean {
		return getTimeout(equipmentId);
	}

	function canTakephoto(equipmentId: Number): Boolean {
		const value = get(equipmentId);
		return value == Dict.UPLOADED || value == Dict.READY || value == "" || !value;
	}

	function doTimeoutCheck(equipmentId: Number) {
		clearTimeout(timer);
		timer = setTimeout(() => {
			setIsTimeout(equipmentId, true);
		}, timeout);
	}

	function showVideo(equipmentId: Number): Boolean {
		const value = get(equipmentId);
		if (value == Dict.UPLOADING || value == Dict.START || value == Dict.READY) {
			switch (value) {
				case Dict.READY:
					if (isTimeout(equipmentId)) {
						return false;
					}
					doTimeoutCheck(equipmentId);
					break;
				case Dict.START:
					clearTimeout(timer);
					setIsTimeout(equipmentId, false);
					break;
				case Dict.UPLOADING:
				case Dict.UPLOADED:
					break;
				default:
					break;
			}

			return true;
		}
		return false;
	}

	return {
		info,
		setStatus,
		set,
		get,
		showVideo,
		clear,
		isUploading,
		resetTimeout,
		canTakephoto
	};
});

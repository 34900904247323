import { BaseService, Service } from "/@/cool";
@Service("badModelReport")
class BadModelReport extends BaseService {
    constructor() {
        super();

        let prefix = "";
        if (this.namespace) {
            prefix = this.namespace.replace(/\//g, ":") + ":";
        }

        this.permission["page"] = prefix + "page";
        // this.permission["list"] = prefix + "list";
        // this.permission["info"] = prefix + "info";
        // this.permission["pageMemberReward"] = prefix + "pageMemberReward";
        // this.permission["pageMemberRewardDetail"] = prefix + "pageMemberRewardDetail";
        this.permission["infoForRepairOrder"] = prefix + "infoForRepairOrder";
    }
    infoForRepairOrder(data: any) {
        return this.request({
            url: "/infoForRepairOrder",
            method: "POST",
            data
        });
    }
    // pageMemberReward(data: any) {
    // 	return this.request({
    // 		url: "/pageMemberReward",
    // 		method: "POST",
    // 		data
    // 	});
    // }

    // pageMemberRewardDetail(data: any) {
    // 	return this.request({
    // 		url: "/pageMemberRewardDetail",
    // 		method: "POST",
    // 		data
    // 	});
    // }
}
export default BadModelReport;

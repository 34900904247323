import { BaseService, Service } from "/@/cool";
@Service("teamRepairOrder")
class TeamRepairOrder extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["downloadAutoModel"] = prefix + "downloadAutoModel";
		this.permission["takephotoImageCount"] = prefix + "takephotoImageCount";
		this.permission["takephotoImages"] = prefix + "takephotoImages";
		this.permission["assignMember"] = prefix + "assignMember";
		this.permission["notice"] = prefix + "notice";
		this.permission["teamCheck"] = prefix + "teamCheck";
		this.permission["downloadPrintModel"] = prefix + "downloadPrintModel";
		this.permission["customerService"] = prefix + "customerService";
		this.permission["page"] = prefix + "page";
		this.permission["list"] = prefix + "list";
		this.permission["info"] = prefix + "info";
		this.permission["photo"] = prefix + "photo";
		this.permission["glb"] = prefix + "glb";
		this.permission["noPassReasonList"] = prefix + "noPassReasonList";
		this.permission["modelPreview"] = prefix + "modelPreview";

		//模型上传
		this.permission["uploadAutoModel"] = prefix + "uploadAutoModel";
	}

	downloadAutoModel(params: any) {
		return this.request({
			url: "/downloadAutoModel",
			method: "POST",
			data: { ...params }
		});
	}

	takephotoImageCount(params: any) {
		return this.request({
			url: "/takephotoImageCount",
			method: "POST",
			data: { ...params }
		});
	}

	takephotoImages(params: any) {
		return this.request({
			url: "/takephotoImages",
			method: "POST",
			data: { ...params }
		});
	}

	// 分配成员
	assignMember(params: any) {
		return this.request({
			url: "/assignMember",
			method: "POST",
			data: { ...params }
		});
	}

	notice(params: any) {
		return this.request({
			url: "/notice",
			method: "POST",
			data: { ...params }
		});
	}

	teamCheck(params: any) {
		return this.request({
			url: "/teamCheck",
			method: "POST",
			data: { ...params }
		});
	}

	downloadPrintModel(params: any) {
		return this.request({
			url: "/downloadPrintModel",
			method: "POST",
			data: { ...params }
		});
	}
	customerService(params: any) {
		return this.request({
			url: "/customerService",
			method: "POST",
			data: { ...params }
		});
	}

	noPassReasonList(params: any) {
		return this.request({
			url: "/noPassReasonList",
			method: "POST",
			data: { ...params }
		});
	}
}
export default TeamRepairOrder;

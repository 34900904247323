import { BaseService, Service } from "/@/cool";
@Service("myModelRepairOrder")
class MyModelRepairOrder extends BaseService {
	downloadAutoModel(params: any) {
		return this.request({
			url: "/downloadAutoModel",
			method: "POST",
			data: { ...params }
		});
	}

	takephotoImageCount(params: any) {
		return this.request({
			url: "/takephotoImageCount",
			method: "POST",
			data: { ...params }
		});
	}

	takephotoImages(params: any) {
		return this.request({
			url: "/takephotoImages",
			method: "POST",
			data: { ...params }
		});
	}

	// 分配成员
	assignMember(params: any) {
		return this.request({
			url: "/assignMember",
			method: "POST",
			data: { ...params }
		});
	}

	notice(params: any) {
		return this.request({
			url: "/notice",
			method: "POST",
			data: { ...params }
		});
	}

	teamCheck(params: any) {
		return this.request({
			url: "/teamCheck",
			method: "POST",
			data: { ...params }
		});
	}

	downloadPrintModel(params: any) {
		return this.request({
			url: "/downloadPrintModel",
			method: "POST",
			data: { ...params }
		});
	}
	customerService(params: any) {
		return this.request({
			url: "/customerService",
			method: "POST",
			data: { ...params }
		});
	}
}
export default MyModelRepairOrder;
